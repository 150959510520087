body{
    font-family: 'Archivo', sans-serif !important;
    background-color: #81bbcb; /* #f4f4f4;*/
    padding-top: 70px;
}




.bg-primary{
    background-color: #227C9D !important
}

.bg-secondary{
    background-color: #878787 !important
}

.card-title{
    flex-grow:3;
    font-size:120%;
    flex-shrink:0;
    flex-basis:auto;
}

.modal-dialog .card-title{
    flex-grow:3;
    font-size:165%;
    flex-shrink:0;
    flex-basis:auto;
}

@media (max-width: 450px) {
    body {padding-top: 70px;}
 }

 @media (max-width: 479.5px) {
    .card-title{font-size: 165%}
    .btn-block{
        font-size: 120%;
    }
    
 }
 
 

.modal-dialog .btn-block{
    font-size: 120%;
}




.navbar-toggler{
    border-color: rgb(71, 71, 71);
}

#info-block {
    position: relative; 
    padding-bottom: 50% !important;
}

#info-block::before {
    content: "";
    border-radius: var(--bs-border-radius);
    background-color: #227C9D;
    background-image: url('https://web3.autonomylab.xyz/img/56_half.png');
    background-size:110%;
    background-repeat: no-repeat;
    background-position: center bottom;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    /*animation: mymove 5.2s cubic-bezier(0.7, 0, 0.84, 0);*/
}

@keyframes mymove {
    from {background-size: 80%}
    to {background-size: 8000%}
  }

#info-block p{
    position:relative;
    font-weight: bolder;
}

.modal-content {
    --bs-modal-bg: rgba(127, 127, 127, 15%);
}

.modal-header {
    --bs-modal-header-border-width: 0;
}

.btn-close{
    --bs-btn-close-color: white
}


.page-footer {
    background-color: #227C9D;
}




