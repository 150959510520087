.wallet-button{
    border: 2px solid black !important;
    border-radius: 20px;
    color: lightgray !important;
 }
  
 .wallet-button:hover {
   border: 2px solid grey !important;
   color: white !important;
 }


 .wallet-button img {
    filter: saturate(0)
 }
